/*
Breadcrumb factory can build mixins which will check a list of specified paths to see if a
logged in user has access. If the user does not have access to all paths, the breadcrumb
parent will fall back to the path specified
*/
export const breadcrumbFactory = {
  build (accessRequirements = [], fallbackParent = 'Home') {
    return {
      breadcrumb () {
        const isInMenu = this.$store.getters['menu/isInMenu']
        const hasAccess = accessRequirements.every(isInMenu)
        return hasAccess ? {} : { parent: fallbackParent }
      }
    }
  }
}

// Build and export any needed breadcrumb mixins below to check user has access to routes
export const stateBreadcrumbMixin = breadcrumbFactory.build(['/officers/page/state-landing-page'])
export const countryBreadcrumbMixin = breadcrumbFactory.build(['/officers/page/country-landing-page'])
