import store from '../store'

/* eslint-disable */
export const reportDownloadMixin = {
  methods: {
    async downloadReport(reportUrl, filename, filetype, payload = null) {
      if (this.setLoadingStatus) {
        this.setLoadingStatus(true)
      }
      try {
        let reportdata = {
          report_path: reportUrl,
          request_method: !!payload ? 'POST' : 'GET',
          payload: !!payload ? JSON.stringify(payload) : null
        }
        await store.dispatch('report/getReportStream', reportdata).then((response) => {
          if (response.headers['content-type'] && response.headers['content-length'] != '0') {
            const blob = new Blob([response.data], { type: response.headers['content-type'] })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = filename + (['csv', 'excel'].includes(filetype) ? '.xlsx' : '.pdf')
            link.click()
            URL.revokeObjectURL(link.href)
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        if (this.setLoadingStatus) {
          this.setLoadingStatus(false)
        }
      }
    },
  },
}

export default reportDownloadMixin
