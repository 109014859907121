<template>
  <div class="security_banner">
    <badge></badge>
    {{ i18n['security-banner'].tcRequiresAdditionalSecurityPermissions }}
  </div>
</template>

<script>
/* eslint-disable */
import badge from '@/assets/svgs/badge.vue'

export default {
  name: 'security-banner',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'security-banner': {
            tcRequiresAdditionalSecurityPermissions: 'This page requires additional security permissions.'
          }
        }
      }
    }
  },
  components: {
    badge: badge,
  },
}
</script>

<style>
.security_banner {
  padding: 30px;
  background-color: darkred;
  color: white;
  text-align: center;
}
</style>
